import React from "react";
import { useNavigate } from "react-router-dom";

const NewsList = (prop) => {

  const {url, img, date, description, id} = prop;
  const navigate = useNavigate();

  return (
    <div className="rounded-lg flex gap-4 flex-col md:flex-row flex-wrap p-4 items-stretch">
      <div>
          <img className="h-72 md:h-48 object-center object-cover w-full md:w-72" src={img} alt={`news-${date}`} />
      </div>
      <div className="md:flex-1 md:ml-8 flex flex-col justify-between">
        <div>
          <span className="font-semibold text-red-500 uppercase text-lg md:text-lg">{date}</span>
        </div>
        <div className="font-bold text-xl mt-4">
          {description}
        </div>
        {url ? <a href={url}  rel="noreferrer" target="_blank">
          <div className="hover:underline text-blue-900 cursor-pointer" >
            Read more...
          </div>
        </a>
          : 
          <div className="hover:underline text-blue-900 cursor-pointer" onClick={() => navigate(`/news/${id}`)}>
            Read more...
          </div>
        }
      </div>
    </div>
  )
}

export default NewsList;