import React from "react";
import { Link, useParams } from "react-router-dom";
import { newsDetail } from "../data/newsDetail";

export const NewsDetail = () => {

  const {newsId} = useParams();

  return (
    <div  className='w-4/5 text-lg text-justify m-auto'>
      <Link to="/news" className="text-xl text-orange underline py-4">
        &lt;&lt; Back to List
      </Link>
      <h3 className="text-orange text-2xl font-bold my-6">{newsDetail.at(newsId).description}</h3>
      <div className="gap-5 whitespace-pre-wrap flex flex-col my-6">
        {newsDetail.at(parseInt(newsId)).content}
      </div>
      <Link to="/news" className="text-xl text-orange underline my-8">
        &lt;&lt; Back to List
      </Link>
    </div>

  )
}