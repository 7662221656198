import React from "react";
import prog1 from './../assets/programs/program1.jpeg'
import prog2 from './../assets/programs/program2.jpeg'
import prog3 from './../assets/programs/program3.jpeg'
import prog4 from './../assets/programs/program4.jpeg'
import { useNavigate } from "react-router-dom";


const Programs = () => {

  const programs = [
    {
      title: "Innovation in material design fabrication and evaluation of performances",
      image: prog1
    },
    {
      title: "Advanced simulation and manufacturing innovation",
      image: prog2
    },
    {
      title: "Artificial intelligence-based and data driven solutions for mining equipment utilisation",
      image: prog3
    },
    {
      title: "Safety, maintainability and reliability of mining equipment",
      image: prog4
    }
  ]
  const navigate = useNavigate();
  return (
    <div className="m-4">
      <h1 className="text-light-orange uppercase text-xl md:text-3xl font-bold">Our Research</h1>
      <div className="flex flex-row md:p-10 p-8 flex-wrap w-full justify-evenly items-start">
        {[...programs].map((program, index) => {
          return (
            <div 
              key={`program-${index}`} 
              onClick={() => {navigate(`our-research/${index}`)}}
              className="m-2 w-full md:w-1/3 xl:w-1/5 cursor-pointer hover:underline">
              <img className="m-4 md:m-0 md:h-48 object-cover" src={program.image} alt={`img-${index}`} />
              <h4 className="text-sm md:text-lg font-semibold uppercase">{program.title}</h4>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Programs;