import React from "react";
import { Avatar } from "@mui/material";
import { advisoryCommittee } from "../data/members";

const AdvisoryTeam = () => {

  return (
    <div className="">
      <p className="">
        The Innovative Composites for Sustainable Mining Advisory Committee will provide advice on scientific direction and relevant national and international opportunities as well as possible commercial developments, while being a promoter of, and advocate for, the Centre's activities.
      </p>
      <h3 className="text-lg font-bold pt-4">Advisory Committee Members</h3>
      {[...advisoryCommittee].map(({name, avatar, title, url, position}, index) => {
        return (
          <div className="flex flex-row gap-4 my-4 justify-around" key={`advisory-committee-${index}`}>
            <div>
              <Avatar 
                  src={avatar} alt={`${name}`} style={{ width: "60px", height: "60px" }}/>
            </div>
            <div className="flex-auto">
              {url ? <a href={url} rel="noreferrer" target="_blank" className="hover:underline">
                <p className="text-lg font-semibold">
                  <span className="font-normal italic">{title}.</span>
                  &nbsp;{name}
                </p>
              </a> : 
              <p className="text-lg font-semibold">
                <span className="font-normal italic">{title}.</span>
                &nbsp;{name}
              </p>
              }
              <p className="text-semibold text-blue-950 text-normal">
                {position}
              </p>
            </div>
          </div>
        )
      })}
      <table className="table-fixed ">
        <tbody>

        
        </tbody>
      </table>
    </div>
  )
}

export default AdvisoryTeam;