import React from "react";
import X from './../assets/logo/x.svg'
import linkedIn from './../assets/logo/linkedInBlack.svg'

const Footer = () => {
  return (
    <div className="bg-gray-100 w-full min-h-32">
      <div className=" p-8 flex flex-row flex-wrap justify-between items-center gap-4">
        <div className="text-gray-600 text-md font-medium">
          Building 4, Room 109, University of Wollongong, NSW, 2522, Australia
          <br/>
          <span className="font-bold">Email: </span>
          <span className="underline">helenj@uow.edu.au</span>
          <br />
          <div className="my-1">
            <span className="font-bold">Connect: </span>
          <a href="https://x.com/ARC_CnM" target="_black" className="inline-block">
            <img src={X} alt="x-logo" className="h-4"/>
          </a>
          <a href="https://www.linkedin.com/company/arc-innovative-composites-for-sustainable-mining/posts/?feedView=all&viewAsMember=true" target="_black" className="ml-2 inline-block">
            <img src={linkedIn} alt="x-logo" className="h-4"/>
          </a>
          </div>
        </div>
        <div className="font-bold text-lg text-gray-500">Copyright © 2024. All rights are reserved</div>
      </div>
    </div>
  )
}

export default Footer;