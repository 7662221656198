import React from "react";
import { Button } from "@mui/material";

const CurrentOpportunities = () => {

  return (
    <div style={{minHeight: "calc(100vh - 80px)", width: "100%", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "space-around"}}>
      <div className="w-full md:w-lg lg:w-3/5 p-6 bg-white border border-gray-200 rounded-lg shadow ">
        <h2 className="text-xl text-light-orange font-bold">Current Opportunities</h2>
        <h3 className="underline text-lg text-blue-950 font-semibold pt-2">Overview</h3>
        <div className="text-sm text-gray-600 text-justify p-2">
          <p>
            We are seeking dynamic, self-motivated and well-qualified candidates with high-quality research experience in the fields of expertise applicable to the relevant opportunity. You must have a passion for applied, industry-focused research, and high quality communication skills to support successful industry collaborations. You will require strong time and project management skills to ensure project deliverables are met, and you must be prepared to spend significant time in industry partner's facilities in order to understand and work collaboratively to address the issues faced by the industry.
          </p>
          <p className="pt-2">
            You will be supported through additional mentoring, training and personal development designed to help prepare you for a career in both academia or industry. You will be working closely with some of Australia's best researchers and industry technologists and will form a vital part of a nationally significant research centre.
          </p>
          <p className="pt-2">
            Current PhD opportunities are shown below, and further opportunities will be added regularly in the coming months. To stay up to date, please register for the ARC Training Centre Innovative Composites for Sustainable Mining newsletter. All applications will need to go through the relevant recruitment processes within the host institution, or as detailed on the opportunity advertisement.
          </p>
        </div>
        <h3 className="underline text-lg text-blue-950 font-semibold pt-4">PhD Opportunity</h3>
        <div className="text-justify text-normal text-gray-600">
            SCHOLARSHIP FOR 3.5 YEARS FULL-TIME STUDY
          <h4 className="underline text-normal text-blue-900 font-semibold pt-3">The environment</h4>
          <div className="text-gray-800 text-sm p-2">
            <p>A national research entity funded by the Australian Research Council (ARC) and headquartered at the University of Wollongong (UOW), ARC Industrial Transformation Training Centre (ITTC) for Innovative Composites for the Future of Mining Equipment (Composites for Sustainable Mining) is comprised of seven Australian universities (participating organisations) and eight local and international industry organisations. The ARC ITTC Composites for Sustainable Mining (the Centre) will commence its operations in May 2023 and operate over the next five years. 
            </p>
            <p className="pt-2">
            The key objective of the Centre is the effective and rigorous training of young professionals through higher degree research that is both custom made and value added, to find appropriate solutions to the current challenges and problems faced by the Mining Equipment, Technology and Service (METS) sectors.  The Centre will not only produce a workforce that meets future skills demands but will also develop sustainable and cost effective mining equipment and high efficiency mining technologies to benefit the nation's manufacturing and mining sectors. The Centre will conduct its industry focused research projects across four programs that are specialised in innovative composites and mining equipment as well as incorporating work-integrated learning into PhD training. 
            These programs are:
            </p>
            <ul className="list-disc pl-8 pt-2">
              <li>
                Innovation in material design fabrication and evaluation of performance (program one), 
              </li>
              <li>
                Advanced simulation and manufacturing innovation (program two), 
              </li>
              <li>
                Artificial intelligence based and data driven solution for mining equipment solution (program three) and 
              </li>
              <li>
                Safety, maintainability and reliability of mining equipment (program four).
              </li>
            </ul>
          </div>
          <h4 className="underline text-normal text-blue-900 font-semibold pt-3">The scholarship</h4>
          <div className="text-gray-800 text-sm p-2">
            <p>
              There are now multiple PhD scholarships available across the Centre's four programs. Valued at $36,161 per annum (2023) and funded by the ARC under its Linkage Schemes, our industry oriented PhD programs aim at enhancing the capabilities of the candidates to work in industry while expediting their research.  
            </p>
            <p className="pt-2">
              The term of these scholarships is three and half years (3.5 years). No scholarship extension is available under this scheme. The PhD candidates will be required to undertake concurrent industry training and or placements along their PhD programs. The recommended completion time for the PhD candidates is 3.5 years. Part timers are unavailable. 
            </p>
            <p className="pt-2">
              The PhD candidates can enrol at anyone of the participating organisations. These universities are University of Wollongong (UOW), University of New South Wales (UNSW), The University of Queensland (UQ), Western Sydney University (WSU), Deakin University, Curtin University and The University of Adelaide.  Cross-university academic supervision is available.
            </p>
          </div>
          <h4 className="underline text-normal text-blue-900 font-semibold pt-3">Eligibility for admission</h4>
          <div className="text-gray-800 text-sm p-2">
            <p>
              Applicants must have completed an Australian university recognised master's by research or bachelor honours degree with first class or second class / division 1 honours, or an equivalent or higher qualification, or submitted other evidence of general and professional qualifications that demonstrates potential to pursue graduate research studies.
            </p>
            <p className="pt-2">
              Applicants must also meet the English proficiency requirement for international students or local applicants with international qualifications set by the participating organisations. Each university may have slightly varied English proficiency requirements for PhD candidates.
            </p>
            <p className="pt-2">
              The academic supervisors may also have their specific requirements. Interested applicants should seek advice from their potential academic supervisors before applying for these PhD scholarships. 
            </p>
            <p className="font-bold pt-2">
              Women in Engineering will be given priority for considerations.
            </p>
          </div>
          <h4 className="underline text-normal text-blue-900 font-semibold pt-3">Apply</h4>
          <div className="text-gray-800 text-sm p-2">
            <ul className="list-disc pl-8 pt-2">
              <li>
                Applicants should submit cover letter detailing relevant experience and research interest; CV with names and addresses of two referees; and Academic transcripts.
              </li>
              <li>
                Both domestic and international prospective candidates are encouraged to apply.
              </li>
              <li>
                Candidates should go through perspective university’s admission and scholarship processes. These universities are: University of Wollongong (UOW), University of New South Wales (UNSW), The University of Queensland (UQ), Western Sydney University (WSU), Deakin University, Curtin University and The University of Adelaide.  Cross-university academic supervision is available
              </li>
            </ul>
          </div>
          <div className="flex flex-row justify-center">
            <a href="https://www.uow.edu.au/research-and-innovation/graduate-research/scholarships/items/prestigious-phd-scholarships-in-engineering-disciplines.php" target="_blank" rel="noreferrer">
              <Button variant="outlined" color="secondary" sx={{bgcolor: "#1f2937", color: "#fff", "&:hover": {"bgcolor": "#fff", color: "#1f2937"}, width: "200px", height:"60px", borderRadius: 0, border: 0, fontWeight: 600}}>Apply</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurrentOpportunities;