import React from "react";
import { unis } from "../data/members";
import { phdCandidate } from "../data/members";
import placeholder from './../assets/avatars/avatar-placeholder.png'

const PhDTable = () => {

  return (
    <div className="w-full p-2 flex flex-col">
      {[...phdCandidate].map((phd, idx) => {
        return (
          
            phd.name ? 
              <div className="rounded-lg flex items-center gap-4 flex-row flex-wrap shadow-md p-4 border-gray-100 border-2" key={`candidate-${idx}`}>
                <div>
                  {phd.avatar ? 
                    <img className="w-36 h-48 object-cover rounded-md" src={phd.avatar} alt={phd.name} />
                    :
                    <img className="w-36 h-48 object-cover rounded-md" src={placeholder} alt="avatar-placeholder" />
                  }
                </div>
                <div className="md:flex-1">
                  <div>
                    <span className="font-bold">{phd.name}</span>
                  </div>
                  <div className="text-sm text-gray-600">{unis[phd.uni].name}</div>
                  <div className="font-serif font-black my-1">{phd.topics}</div>
                  <div><span className="capitalize font-semibold">Primary supervisor: </span><span>{phd.primarySupervisor}</span></div>
                  {phd.coSupervisors && <div><span className="capitalize font-semibold">Co supervisors: </span><span>{phd.coSupervisors}</span></div>}
                  <div className="text-sm text-gray-700">
                    {phd.plannedCompletionDate ? 
                      <>{phd.commencementDate} - <span className="italic">Est. </span>{phd.plannedCompletionDate}</>
                    : 
                      <><span className="italic">Planned commencement date: </span>{phd.commencementDate}</>
                    }
                  </div>
                </div>
              </div>
              
              :
              <div className="rounded-lg shadow-md p-4 border-gray-100 border-2" key={`candidate-${idx}`}>
                <div>
                  <span className="text-red-800">Under Recruitment</span>
                </div>
                <div className="text-sm text-gray-600">{unis[phd.uni].name}</div>
                {/* <div className="font-serif font-black my-1">{phd.topics}</div> */}
                <div><span className="capitalize font-semibold">Primary supervisor: </span><span>{phd.primarySupervisor}</span></div>
              </div>

        
        )
      })}
    </div>
  )
}

export default PhDTable;