import React from "react";
import { news } from "../data/news";
import NewsList from "../components/NewsList";

const Post = () => {

  return (
    <div className="m-4">
      <h1 className="text-light-orange uppercase text-xl md:text-3xl font-bold">News</h1>
      <div className="w-full p-2 flex flex-col">
        {[...news].map((item, idex) => {
          return (
            <NewsList key={`news-${idex}`} {...item} />
          )
        })}
      </div>
    </div>
  )
}

export default Post;