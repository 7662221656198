import news1 from './../assets/news/news.jpg';
import news2 from './../assets/news/news2.png'
import meeting from './../assets/news/meeting.jpeg'
import night from './../assets/news/night.jpg'
import baoSteel from './../assets/news/baoSteel.jpg'
import visitor from './../assets/news/visitor.jpg'
import adobe1 from './../assets/news/adobe-1.jpg'
import dean from './../assets/news/dean.jpg'
import img020924 from './../assets/news/020924.jpeg'
import confer from './../assets/news/content/03-10-24.jpg'

export const news = [
  {
    img: confer,
    date: "3 October 2024",
    description: "Green Mining Conference 2024 and First HDR Forum of ARC ITTC for Innovative Composites for Sustainable Mining. ",
    id: 5
  },
  {
    img: img020924,
    date: "2 September 2024",
    description: "A new industry partner joined ARC Innovative Composites for Sustainable Mining Equipment.",
    id: 4
  },
  {
    img: dean,
    date: "21 August 2024",
    description: "The Centre welcomed the Dean of Engineering from Curtin University.",
    id: 3
  },
  {
    img: visitor,
    date: "20 August 2024",
    description: "Distinguished Visitor of the Centre: Professor Yuanping Cheng of China University of Mining and Technology (CUMT) 's visit",
    id: 0
  },
  {
    img: adobe1,
    date: "9 August 2024",
    description: "Use manufacturing technologies to achieve 2050 net zero carbon target in a rural setting",
    id: 1
  },
  {
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7217392765305856000/?actorCompanyId=99349140",
    img: meeting,
    date: "5 July, 2024",
    description: "UOW works with its industry partner HBIS to develop clean metal technologies"
  },
  {
    url: "https://portal.engineersaustralia.org.au/event/2023/09/rtsa-student-rail-industry-networking-night-51281",
    img: night,
    date: "2024",
    description: "RTSA student rail industry networking night"
  },
  {
    url: "https://www.uow.edu.au/media/2024/uow-led-project-to-create-cleaner-greener-stainless-steel-manufacturing-process-.php",
    img: news2,
    date: "25 June, 2024",
    description: "The Australian Research Council (ARC) last week (Friday 21 June) announced the recipients of the Linkage Project Scheme Round 2, with the UOW project sharing in $43-million worth of funding."
  },
  {
    img: baoSteel,
    date: "21 June 2024",
    description: "The world's largest steel producer funds UOW to undertake low carbon emission research projects",
    id: 2
  },
  {
    url: "https://www.australianmanufacturing.com.au/university-of-wollongong-houses-new-arc-training-centre-for-sustainable-mining/",
    img: news1,
    date: "12 October, 2023",
    description: "The University of Wollongong's Innovation Campus is now home to the Australian Research Council's new Training Centre for Innovative Composites for the Future of Sustainable Mining Equipment. "
  },
]
