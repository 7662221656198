import React from "react";
import { management } from "../data/members";
import Card from "./Card";

const ManageTeam = (prop) => {

  return (
    <>
      {[...management].map((member, index) => {
        return (
          <Card {...member} contact={prop.contact} key={`member-${index}`} />
        )
      })}
    </>
  )
}

export default ManageTeam;