import React, {useState} from "react";
import ManageTeam from "../components/ManageTeam";
import ChiefInvestigators from "../components/CheifInvestigators";
import PartnerInvestigators from "../components/PartnerInvestigators";
import PhDTable from "../components/PhDTable";

const OurPeople = () => {

  const [active, setActive] = useState(0);
  const tabs = [
    "Management Committee",
    "Chief Investigators",
    "Partner Investigators",
    "Research Fellows",
    "PhD Candidates"
  ]

  return (
    <div className="py-1" style={{minHeight: "calc(100vh - 80px)"}}>
    <div className="md:flex md:flex-row pl-3 md:pl-0 ">
      <div className="text-grey-500 md:w-1/4 md:text-right">
        {[...tabs].map((tab, index) => {
          return (
            <div key={`tab-${index}`} 
                onClick={() => {setActive(index)}}
                className={`${active === index && "text-light-orange border-l-2 md:border-l-0 md:border-r-2 border-light-orange"} cursor-pointer py-2 pr-4 pl-2 hover:underline`}
                >
              <span className="">{tab}</span>
            </div>
          )
        })}
      </div>
      <div className="bg-gray-300 min-w-px hidden md:block md:mr-4" />
      <div className="md:w-3/4 mt-4 md:mt-0 ">
        <section className={`leading-7 text-gray-800`}>
          <h1 className="text-2xl font-bold font-sans">{tabs[active]}</h1>
          {active === 0 && <ManageTeam contact={true} />}
          {active === 1 && <ChiefInvestigators variant="chef" />}
          {active === 2 && <PartnerInvestigators variant="partner"/>}
          {active === 3 && <ChiefInvestigators variant="fellow"/>}
          {active === 4 && <PhDTable/>}
        </section>
      </div>
    </div>
  </div>
  )
}

export default OurPeople;