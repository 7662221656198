import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff9800',
    },
    secondary: {
      main: '#011640',
    },
    error: {
      main: '#d50000',
    },
  },
  typography: {
    h1: {
      fontFamily: 'DM Serif Text',
    },
    h2: {
      fontFamily: 'DM Serif Text',
    },
  },
})