import React from "react";
import { chiefInvestigators } from "../data/members"; 
import { fellow } from "../data/members"; 
import UniCard from "./UniCard";

const ChiefInvestigators = (prop) => {
  return (
    <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      {prop.variant === "chef" ? [...chiefInvestigators].map((member, index) => {
        return (
          <UniCard {...member} key={`chief-investigator-${index}`}/>
        )
      })
      : [...fellow].map((member, index) => {
        return (
          <UniCard {...member} key={`chief-investigator-${index}`}/>
        )
      })
    }
    </div>
  )
}

export default ChiefInvestigators;