import React from "react";
import program1 from './../assets/programs/program1_logo.jpg';

const Program1 = () => {
  return (
    <>
        {/* <h1 className="text-xl font-bold mb-4 text-blue-600">Steel Composites Development</h1> */}
        <p className="my-4 text-normal text-gray-600">
            Two types of steel composites will be developed. Composite-1 consists of white cast iron/Mn steels as a compositing metal and low carbon steel as a base metal, and composite-2 is composed of dual-phase stainless steel, i.e., compositing metal and high strength steel.
        </p>
        <h2 className="text-lg font-semibold mb-2">Key Researchers and Organizations</h2>
        <ul className="list-disc list-inside mb-4">
            <li>Key CI: J. Yoon (Deakin)</li>
            <li>CIs: J. Zhang (UNSW), C. Zhang (UOW), Z. Jiang (UOW), G. Zhang (UOW), S. Karekal (UOW), R. Yang (WSU), Y. Zhang (WSU), L. Su (UOW)</li>
            <li>POs: Bisalloy, Roobuck, Baosteel, HBIS, Komatsu, Top Iron and Australia L&Y</li>
        </ul>
        <img src={program1} alt="arc-program1" className="md:w-1/2 object-cover w-4/5 block m-auto py-5"/>
        <h2 className="text-lg font-semibold mb-2">Focus Areas</h2>
        <div className="ml-4">
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Impact Strength Test</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers:</span> Key CIs from Deakin, UNSW, and UOW and 1 HDR<br />
                  <span className="font-semibold">Industry Partners:</span> PIs from Roobuck and Bisalloy
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Steel Composite Design and Manufacturing</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers:</span> CIs from WSU and UOW and 1 HDR<br />
                  <span className="font-semibold">Industry Partners:</span> PI from Bisalloy, Baosteel, and HBIS
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Corrosion Test and Material Characterisation Analysis</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers:</span> CIs from UOW, UNSW, and 1 HDR<br />
                  <span className="font-semibold">Industry Partners:</span> PIs from Komatsu, Top Iron, and Australia L&Y
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Wear Resistance, Deformation Analysis and Mechanism Research</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers:</span> 1 ICPD and 3 HDRs
              </p>
          </div>
        </div>
    </>
  )
}

export default Program1;