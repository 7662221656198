import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Program1 from "../components/Program1";
import Program2 from "../components/Program2";
import Program3 from "../components/Program3";
import Program4 from "../components/Program4";

const OurResearch = () => {

  const {researchId = "0"} = useParams();

  const tabs = [
    {title: "Program 1",
      name: "Innovation in Material Design, Fabrication and Evaluation of Performances "
    },
    {
      title: "Program 2",
      name: "Advanced Simulation and Manufacturing Innovation"
    },
    {
      title: "Program 3",
      name: "Artificial Intelligence-based and Data-driven Solutions for Mining Equipment Utilisation "
    },
    {
      title: "Program 4",
      name: "Safety, Maintainability and Reliability of Mining Equipment (3 HDRs)"
    }
    
  ]

  const navigate = useNavigate();
  const active = parseInt(researchId);

  return (
    <>
      <div style={{minHeight: "calc(100vh - 80px)", padding: "5px 5px 0 5px"}}>
        <div className="md:flex md:flex-row">
          <div className="text-grey-500 md:w-1/4 md:text-right">
            {[...tabs].map((tab, index) => {
              return (
                <div key={`tab-${index}`} 
                    onClick={() => {navigate(`/our-research/${index}`)}}
                    className={`${active === index && "text-light-orange border-l-2 md:border-l-0 md:border-r-2 border-light-orange"} cursor-pointer py-2 pr-4 pl-2 hover:underline`}
                    >
                  <span className="">{tab.title}</span>
                </div>
              )
            })}
          </div>
          <div className="bg-gray-300 min-w-px hidden md:block md:mr-4 pl-3 md:pl-0" />
          <div className="md:w-3/4 mt-4 md:mt-0">
            <section className={`leading-7 text-gray-800`}>
              <h1 className="text-2xl font-bold font-sans">{tabs[active].name}</h1>
              {active === 0 && <Program1 />}
              {active === 1 && <Program2 />}
              {active === 2 && <Program3 />}
              {active === 3 && <Program4 />}
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurResearch;