import { ThemeProvider } from '@mui/material';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routers from './routers';
import { themeOptions } from './theme';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <BrowserRouter>
        <ScrollToTop>
          <Routers />
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
