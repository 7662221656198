import img2008241 from './../assets/news/content/20-08-24-1.png'
import img2008242 from './../assets/news/content/20-08-24-2.png'
import img210824 from './../assets/news/content/21-08-24.png'
import img020924 from './../assets/news/content/02-09-24.png'
import confer from './../assets/news/content/03-10-24.jpg'

export const newsDetail = [
  {
    date: "20 August 2024",
    description: "Distinguished Visitor of the Centre: Professor Yuanping Cheng of China University of Mining and Technology (CUMT) 's visit",
    content: 
    <>

      <div>
        Distinguished Professor Yuanping Cheng of China University of Mining and Technology (CUMT) visited the Australian Research Council (ARC)-Industrial
          Transformation Training Centre (ITTC) for Innovative Composites for the Future of
          Sustainable Mining Equipment on the afternoon of 20th August. Distinguished Professor Cheng
          is a globally known expert and scholar in coal mine methane and safety engineering in mining.
          He has been granted over 30 Chinese invention patents, published 8 academic monographs,
          and has authored more than 230 papers indexed by SCI and over 90 papers indexed by EI. 
      </div>

      <div>
        CUMT and UOW have enjoyed a fruitful partnership spanning over 14 years, resulting
    in more than 70 joint publications in top-quality journals and international conferences. This
    collaboration has also led to joint research projects, industry-driven initiatives, and the cohosting of international symposia and conferences, including the Green Mining Conference.
      </div>

    <div>
    The visit was facilitated by Professor Ting Ren, Deputy Director of ARC Training
  Centre, and featured a workshop attended by Professor Xueqiu He, a visiting professor at UOW
  and an advisory committee member of ARC Training Centre, along with several HDR students.
  </div>

  <div>
    During the workshop, Distinguished Professor Yuanping Cheng delivered a compelling
  presentation titled “Micropore Filling of Gas and New Classification Methods of Pore
  Structures in Coal”. His presentation covered key aspects of his research, including
  advancements in understanding complex pore structures in coal, gas occurrence characteristics,
  and the latest methods for characterising and classifying new pore types, highlighting the
  significance of these findings for both research and engineering applications
  </div>

  <div>
    PhD Candidate Ming Cheng followed with a presentation on “Precise Evaluation of
  Fracture Porosity of Coal Body: Key Factor in Assessing Free Gas Outburst Expansion Energy”.
  Professor Ting Ren then shared insights from recent research on gas rapid sorption tests, new
  findings related to gas expansion energy, the impact of rapid gas desorption on coal structure,
  as well as the difference between normal and tight coal in terms of mineral contents and
  fractures
  </div>

    <div>
    The workshop also included engaging discussions on techniques for differentiating
  normal and tight coal, as well as technologies aimed at reducing the risks of coal and gas
  outbursts. The event concluded with a focus on potential future collaborations between CUMT
  and UOW.
  </div>

<div>
    Following the workshop, Distinguished Professor Yuanping Cheng visited the ARC
  Training Centre in Building 4, where he engaged with HDR students and postdoctoral fellows,
  fostering further academic exchange and collaboration.
  </div>
    <img src={img2008241} alt='meeting-img-1' className='w-4/5'/>
    <img src={img2008242} alt='meeting-img-1' className='w-4/5'/>
  </>
  },
  {
    date: "9 August 2024",
    description: "Use manufacturing technologies to achieve 2050 net zero carbon target in a rural setting",
    content: 
    <>
      <div>
      On 9 Aug, the CEO and her team from a large local manufacturing firm, VLI Conveyors Industrial Technology
Pty Ltd (VLIC) visited the ARC innovative composites and mining equipment (the Centre) and the research
team led by the Centre Director, Distinguished Professor Zhengyi Jiang.
      </div>
      <div>
      VLIC have been supplying specialist mining facilities/equipment and related services to the local resources
industry for more than 30 years. VLIC provides a comprehensive and diverse range of facilities/equipment
including rollers, drilling rigs, mining equipment, rock cutting tools, industry pump and conveyor systems, all
designed and manufactured by our own team to the highest specifications. VLIC, we aims to provide industryleading technology, innovation and service solutions to all of its customers. 
      </div>
      <div>
      UOW, through the research team led by Distinguished Professor Jiang, is equipped with world class facilities
such as Hille 1000 rolling mill and MFT-5000 Multi-functional Tribometer, industry focused minds, and have
produced forefront research outcomes in material design and its manufacturing technologies.
      </div>
      <div>
      At the initial official meeting between VLIC and the Centre on 9 Aug, a mutual interest was developed and
discussed. The activities will include: to develop innovative methods using novel composites, to explore
manufacturing technologies to produce rollers, conveyor systems and rock cutting tools with reduced weights, to
enhance wear and corrosion resistance, and to make the facilities/equipment more efficiently, less energy
consumptions and with reduced operational costs for manufacturing and mining equipment industry. The
outcomes of the proposal will transform the delivery to wide range of customers in rural and local setting
especially, and help achieve our 2050 net zero carbon target and secure the sustainable future of manufacturing
transportation industry
      </div>
      <div>
      VLIC is also interested in being part of the team to facilitate HDR training, workshops and field trips to its industry
customers sites. Both the Center and VLIC will surely benefit from the proposed research collaboration and
engagement activities.
      </div>
      <div>
      VLIC visitors also visited UOW’s forefront research labs on the main campus. The visit concluded with a
      resounding success in the early afternoon on 9 Aug. 
      </div>

    </>
  },
  {
    date: "21 June 2024",
    description: "The world's largest steel producer funds UOW to undertake low carbon emission research projects",
    content: <>
      <div>
      On the afternoon of 21 June, a senior delegation from BaoWu and Baosteel Australia Joint
Research and Development Centre (BAJC) visited the ARC ITTC Innovative Composites for the
Future of Sustainable Mining Equipment at UOW. BaoWu is the largest steel producer in the
world by volume (2023). 
      </div>
      <div>
      The delegation was comprised of the core leadership team of Baosteel. They included Mr Jixin
Zou (Chairman, Baosteel), Mr Changdeng Qin (General Manager, Strategy & Planning Dept.,
and Director, Sci & Tech. Innovation Dept., BaoWu), Dr Jun Wu (Vice President, and Director of
Central Research Institute, Baosteel), Mr Yunyong Liu (Director, Administrative Office,
Baosteel), Mr Yongzhu Ma (Technical Director, Center for Basic Research and Applied
Innovation, Baosteel), Mr Yijun Shen (General Manager, Baosteel Australia), and Mr Puchun
Zheng (Sales Manager, Baosteel Australia). The delegation also included Professor Geoff Wang
(Director of BAJC, UQ).
      </div>
      <div>
      UOW attendees included Senior Professor Eileen McLaughlin (Acting DVC(R&SF)),
Distinguished Professor Zhengyi Jiang, (Director, ARC ITTC Innovative Composites for the
Future of Sustainable Mining Equipment), Ms Sharon Martin (Director, Research Services
Office), Mr Stefan Delfgou (Associate Director, Research Services Office), Senior Professor
Michel , Philip, Jiaotao, Senior Professor Huijun Li (Associate Dean (HDR), EIS), Matin…Other
EIS colleagues and management team members, and colleagues from the UOW Global Strategy
Division also attended the meeting with the delegation. 
      </div>
      <div>
      BAJC is a world-first joint venture between Baosteel and four Australian universities (the
University of Queensland, the University of New South Wales, Monash University and the
University of Wollongong). Established in 2011, it seeks to create an enduring collaboration
between Baosteel and these Australian universities, and to conduct research and provide
innovative technologies in areas of interest to Baosteel. Following the merger between Baosteel
and the Wuhan Iron & Steel Group, BAJC welcomed Deakin University as another participant in
February 2019. Based at the University of Queensland, BAJC has been exploring and developing
new knowledge and technologies in selected areas of particular significance for Baosteel's longer
term, strategic development and business activities.
      </div>
      <div>
      In last 2 years, the BAJC Board approved 18 projects with total Baosteel funding of over $3M
commencing in January 2023. Five of these projects were granted to UOW EIS researchers.
These projects are:
        <ul className='list-disc pl-8'>
          <li>
          The application of autonomous multi-agent technology in intelligent modelling,
optimization, decision-making and simulation of the steel manufacturing.
</li>
<li>
Formability and forming simulation of novel super ferritic stainless steel bipolar plate
applied in hydrogen fuel cell.
</li>
          <li>
          A BDI agent platform for smart modelling and integrated simulation of production
          scheduling, cargo loading, and logistics in the steel industry.
          </li>
          <li>
          Mechanism and practical research on surface quality improvement of hot rolled plate
          steels under high chloridoid water based nano lubrication.
          </li>
          <li>
Investigating solidification defects and improving quality of nitrogen-bearing steel using
electric furnace smelting and continuous casting.
          </li>
        </ul>
        BAJC is currently a key industry partner for the ARC Training Centre for Innovative Composite
for the Future of Sustainable Mining Equipment, led by UOW’s Distinguished Professor Zhengyi
Jiang. Following the meeting with the UOW management team, Professor Jiang took the
delegation to visit UOW’s forefront rolling, welding and manufacturing research labs, and also showcased industry-relevant results from projects funded by Baosteel during the lab
demonstrations. The projects relate to ironmaking, steelmaking, steel rolling, energy pipelines,
mining, and AI.
      </div>
      <div>
      As Professor Jiang mentioned during the lab demonstrations, “the outcomes of these industry
projects not only promote the application of innovative technologies and the development of new,
high value and low carbon emission products in Baosteel, but have also strengthened the academic
and industry collaborations and provided tangible benefits to both industry and participating
Australian universities.
      </div>
    </>
  },
  {
    date: "21 August 2024",
    description: "The Centre welcomed the Dean of Engineering from Curtin University.",
    content: <>
    <div>
    On 21 Aug, the Dean of Engineering at Curtin University and Chief Investigator (CI) of ARC Innovative
Composites for Sustainable Mining Equipment (the Centre), Professor Vishnu Pareek visited the
Centre.
    </div>
    <div>
    Professor Vishnu Pareek’s expertise lies in process simulations and design, with a particular
emphasis in applying these tools to industrial-scale problems. He has conducted extensive applied
research/consultancy for several multinational companies such as Chevron, Woodside and BP. In
2019, he was awarded the title of John Curtin Distinguished Professor for his excellence in research
and academic leadership. Vishnu’s expertise and leadership play an important role for the Centre’s
Program three, which focuses on Artificial Intelligence based and Data driven solutions for mining
equipment utilisation. During the half day visit, Vishnu had a meeting with the Centre’s Director,
Distinguished Professor Zhengyi Jiang, Deputy Director Professor Ting Ren and the Business Manager
in Centre head office, R109 Building 4. Vishnu advised the progress of the project at Curtin
University. A high quality ICHDR candidate has been secured and is scheduled to enrol later this year
at Curtin University. The group also discussed Centre ICHDR candidates’ activities in October 2024
and 2025 plans.
    </div>
    <div>
    Following the Centre business meeting, Vishnu was invited to UOW’s Café Hemingway in building 67
for a sunny work lunch before meeting with ED of EIS, Professor Gursel Alici. Professor Vishnu
Pareek headed back to Canberra later in the afternoon.
    </div>
    <img src={img210824} alt='visiting' />
    </>
  },
  {
    date: "2 September 2024",
    description: "A new industry partner joined ARC Innovative Composites for Sustainable Mining Equipment.",
    content: <>
      <div>
      On 2 Sept ARC Innovative Composites for the Future of Sustainable Mining Equipment (the Centre)
headquartered at University of Wollongong (UOW) Director Distinguished Professor Zhengyi Jiang
and Deputy Director Professor Ting Ren welcomed a delegation comprised of Dr Liang Zhao,
Yunpeng Wang, and Shuangcheng Dai, from Azure Mining Technology (AMT) Pty Ltd.
      </div>
      <div>
      Joined the Centre earlier this year, AMT became the latest addition to the Centre’s Partner
Investigator team (PI), contributing both industry expertise and cashing funding. AMT is a subsidiary
of China Coal Technology and Engineering Group (CCTEG). CCTEG started from exporting complete
sets of coal mining equipment, the company has grown into a comprehensive international supplier
of EPC project, technological and engineering services. The Company is committed to bringing the
leading-edge technical advantages to the world, particularly in the fields of technology and planning,
coal engineering, coal mining equipment, coal mining safety, cola chemical industry, clean energy
and environmental protection. CCTEG also provide complete sets of equipment and technical
solutions for global coal enterprises with high-quality, reliable and safety services.
      </div>
      <div>
      During the visit, the AMT delegation visited the Centre head office located in R109 Building 4, a
number of research forefront labs and UOW main campus. A very productive discussion was held on
a number of joint research projects to be undertaken in the next three to four years. The team also
discussed the avenues and expected outcomes to co mentor the Centre ICHDR candidates and
young professionals.
      </div>
      <div>
      The delegates finished on a highly positive note that AMT and the Centre will continue the
      collaborative work on developing future technology for green mining and energy.
      </div>
      <img src={img020924} alt='news-020924' />
    </>
  },
  {
    date: "3 October 2024",
    description: "Green Mining Conference 2024 and First HDR Forum of ARC ITTC for Innovative Composites for Sustainable Mining. ",
    content: 
      <>
        <div>
          The ARC ITTC for Innovative Composites for the Future of Sustainable Mining Equipment (the Centre) has successfully hosted Green Mining Conference 2024 held at the University of Wollongong from 1 to 3 October. UOW’s Interim Vice Chancellor, Professor John Dewar, Centre Director Distinguished Professor Zhengyi Jiang, and Deputy Director, Professor Ting Ren delivered opening speeches and welcomed over 150 attendees from NSW, interstate and overseas. Professors Jixiong Zhang (Vice-president, China University of Mining and Technology, Hairong Dong (Vice-president, Shandong University of Science and Technology), and Xikui Sun (Chief Engineer, Shandong Energy Group) also delivered their respective opening addresses. UOW’s PVC (Impact) Professor Bradley Williams was the MC for the Conference Opening Ceremony.
        </div>
        <div>
          In his opening speech, Distinguished Professor Zhengyi Jiang indicated that “The Mining Equipment, Technology and Service (METS) sector contributes $92 billion to the Australian economy, provides over half a million jobs, and has an average growth rate of 7%. The METS sector will require a diverse and flexible workforce of highly skilled engineers with innovative thinking and broad knowledge of future technologies, economic and commercialisation opportunities, and environmental and social responsibilities.” Therefore “As sustainable mining operations become more common in the future, we need innovative composites to manufacture the desired mining equipment, such as mining trucks and mining pumps, to produce mining products to drive the renewable energy transition.” said Professor Jiang.
        </div>
        <div>
          The Conference has held two Keynote Plenary Sessions and six parallel sessions on Day 2 and Day 3 respectively. The talks over the two day conference included a wide range of mining industry challenges such as risk management, health and safety, current status and technology, green and low carbon technology in coal mining, innovative composites (for simulated mining testing rig) and use of AI in mining industry. As Deputy Director Professor Ting Ren said “these exchanges/discussions are vital to driving progress in min hazard prevention and control, for a greener and more sustainable industry for the future” 
        </div>
        <div>
          As part of the conference, the HDR forum on 3 Oct, focused in particular on young professionals’ practical and academic skill development. There were thirty HDR presentations during the Forum, including those students commenced in Sept 2024. Six HDR students won “best presentation awards” at the 1st HDR forum of ARC ITTC innovative composites for sustainable mining equipment. Centre Director Zhengyi Jiang presented the certificates and token gifts to the exciting HDR students (awardees).
        </div>
        <div>
          The Green Mining Conference 2024 provided great opportunities for researchers, engineers and higher degree research students to exchange current ideas in green mining technology worldwide, and to provide advice to solve challenges in the mining industry. 
        </div>
        <div>
          The Conference Gala Dinner was held at City Beach Function Centre on 2 Oct. The Conference registration commenced on the evening of 1 Oct, and concluded with a very positive note on 3 Oct. 
        </div>
        <div>
          The Conference also features a sideline meeting on 2 Oct chaired by UOW’s Interim DVC (Research and Sustainable Futures), Senior Professor Eileen McLaughlin, and post conference technical tours to Coal Services and Green Gravity on the morning of 4 Oct, both of which are the Illawarra community companies.  
        </div>
        <img src={confer} alt='conference' className='w-4/5 m-auto' />
      </>
  },
]