import React, {useState} from "react";
import X from './../assets/logo/x.svg'
import linkedIn from './../assets/logo/linkedIn.svg'

const Contact = () => {

  const [result, setResult] = useState("");


  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("sending");
    const formData = new FormData(event.target);

    formData.append("access_key", "b4117cba-300d-4a55-b691-5d700de19926");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });
    const data = await response.json();
    if (data.success) {
      setResult("success");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult("error")
    }
  };

  return (
    <div style={{minHeight: "calc(100vh - 80px)", width: "100%", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "space-around"}}>
      <div className="md:w-lg w-full m-4 md:my-8 lg:w-3/5">
        <h2 className="text-lg md:text-3xl font-bold text-light-orange font-mono">Contact Us</h2>
        <div className="text-lg text-gray-600 pt-4 font-normal">
          <p>
            Thank you for your interest in the ARC Training Centre Innovative Composites for Sustainable Mining 
          </p>
          <br />
          <p>
            If you'd like to receive more information, discuss opportunities or have general questions about our research, please contact us via the options below.
          </p>
        </div>
        <div className="text-2xl text-red-600 font-semibold mt-4 font-sans">
          <p className="uppercase tracking-wide">Address</p>
          <p className="text-blue-700 font-bold text-lg pl-4">
            Building 4, Room 109, University of Wollongong, NSW, 2522, Australia.
          </p>
        </div>
        <div className="text-2xl text-red-600 font-semibold mt-4 font-sans">
          <p className="uppercase tracking-wide">Email</p>
          <a href="mailto:arc_ innovative_composites_sustainable_mining@uow.edu.au">
            <p className="text-blue-700 font-bold text-sm md:text-lg pl-4 hover:underline">
              helenj@uow.edu.au
            </p>
          </a>
        </div>
        <div className="text-2xl text-red-600 font-semibold mt-4 font-sans">
          <p className="uppercase tracking-wide">Social Media</p>
          <div className="m-1">
            <a href="https://x.com/ARC_CnM" target="_black" className="pl-4 inline-block">
              <img src={X} alt="x-logo" className="h-8"/>
            </a>
            <a href="https://www.linkedin.com/company/arc-innovative-composites-for-sustainable-mining/posts/?feedView=all&viewAsMember=true" target="_black" className="pl-4 inline-block">
              <img src={linkedIn} alt="x-logo" className="h-8"/>
            </a>
          </div>
        </div>
        <div className="text-2xl text-red-600 font-semibold mt-4 font-sans">
          <p className="uppercase tracking-wide">Subscribe</p>
        </div>
        <div className="mt-4 rounded-xl bg-white border-solid border-light-orange shadow-md hover:shadow-lg hover:shadow-light-orange shadow-light-orange ">
            <p className="text-gray-600 text-center text-normal p-4">
              Keep up to date with what is happening in the Training Centre
            </p>
            <form onSubmit={onSubmit}>
              <div className="flex flex-row flex-wrap content-start md:content-evenly p-4 gap-2">
                <div className="flex flex-row flex-wrap w-full justify-between">
                  <div className="md:w-1/2 w-full px-2">
                    <label htmlFor="fname" className="block mb-2 text-sm font-medium text-gray-900">First Name*</label>
                    <input type="text"
                      className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                      id="fname" name="fname" required/>
                  </div>
                  <div className="md:w-1/2 w-full px-2">
                    <label htmlFor="sname" className="block mb-2 text-sm font-medium text-gray-900">Surname</label>
                    <input type="text"
                      className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                      name="sname" id="sname"/>
                  </div>
                </div>
                <div className="md:w-1/2 w-full px-2">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email*</label>
                  <input type="email" 
                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                    name="email" id="email" required/>
                </div>
                <div className="w-full px-2">
                  <label htmlFor="org" className="block mb-2 text-sm font-medium text-gray-900">Organisation</label>
                  <input type="text" 
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    name="org" id="org"
                    placeholder="University, Company, Institution, etc..."
                    />
                </div>
                <div className="w-full px-2">
                  <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                  <textarea 
                    name="message"
                    id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Leave a message for us..."></textarea>
                </div>
                <div className="w-full flex flex-row justify-around">
                {
                  result === 'sending'
                  &&
                  <button 
                    disabled
                    className="w-36 h-12 rounded-md bg-gray-400 hover:opacity-80 text-white font-semibold" 
                    >
                    <div role="status" className="ml-12">
                      <svg aria-hidden="true" className="w-10 h-10 me-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </button>
                }
                {
                  result === 'success'
                  &&
                  <button 
                    disabled
                    className="w-36 h-12 rounded-md bg-green-500 hover:opacity-80 text-white font-semibold" 
                    >
                    <div role="status" className="ml-12">
                      <svg className="w-10 h-10 me-2 text-white dflex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                    </div>
                  </button>
                }
                {
                  result === 'error'
                  &&
                  <button 
                    disabled
                    className="w-36 h-12 rounded-md bg-red-500 hover:opacity-80 text-white font-semibold" 
                    >
                      Retry
                  </button>
                }
                {
                  result === ""
                  &&
                  <button 
                    className="w-36 h-12 rounded-md bg-light-orange hover:opacity-80 text-white font-semibold" 
                    type="submit">
                      Submit
                  </button>
                }
                </div>
              </div>
            </form>
          </div>
      </div>
    </div>
  )
}

export default Contact;