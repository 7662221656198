import React from "react";
import { Avatar } from "@mui/material";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const CompanyCard = (prop) => {

  const {name, company, avatar} = prop

  return (
    <div className="flex flex-row gap-4 my-4 justify-around">
      <div>
        <Avatar 
            src={avatar} {...stringAvatar(name)} style={{ width: "60px", height: "60px" }}/>
      </div>
      <div className="flex-auto">
        <p className="text-lg font-semibold ">
          {name}
        </p>
        <p className="text-semibold text-blue-950 text-normal">
          {company}
        </p>
      </div>
    </div>
  )
}

export default CompanyCard;