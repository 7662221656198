import React from "react";
import { partnerInvestigators } from "../data/members";
import CompanyCard from "./CompanyCard";
import { unis } from "../data/members";
import { phdCandidate } from "../data/members";

const PartnerInvestigators = (prop) => {

  const members = {
    partner:
        [...partnerInvestigators].map((member, index) => {
          return (
            <CompanyCard {...member} key={`partner-investigator-${index}`}/>
          )}),
    phd: 
      [...phdCandidate].map((member, index) => {
        return (
          <CompanyCard {...member} company={unis[member.uni].name} key={`fellow-${index}`}/>
        )
      })
  }

  return (
    <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      {
        members[prop.variant]
    }
    </div>
  )
}

export default PartnerInvestigators;