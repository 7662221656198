import React from "react";
import program3 from './../assets/programs/program3_logo.jpg';

const Program3 = () => {
  return (
    <>
        <p class="my-4 text-normal text-gray-600">
          This program is focusing on artificial intelligence-based and data-driven application for improving overall equipment effectiveness with the combination of mine automation, smart drilling technologies, smart sensors, and big data analytics.
        </p>
        <h2 class="text-lg font-semibold mb-4">Key Researchers and Organizations</h2>
        <ul class="list-disc list-inside mb-4 text-gray-800">
            <li>Key CI: T. Ren (UOW); CIs: P. Knights (UQ), M. Kizil (UQ), G. Si (UNSW), S. Karekal (UOW), V. Pareek (Curtin), M. Karakus (Adelaide)</li>
            <li>POs: Roobuck, Komatsu, SNS and Top Iron.</li>
        </ul>
        <img src={program3} alt="arc-program3" className="md:w-1/2 object-cover w-4/5 block m-auto py-5"/>
        <h2 className="text-lg font-semibold mb-2">Focus Areas</h2>
        <div className="ml-4">
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Mine automation and roadway development</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>key CIs from UOW, UQ and Adelaide, 1 ICPD, 1 HDR<br />
                  <span className="font-semibold">Industry Partners: </span>Komatsu, Roobuck 
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Smart sensors and smart drilling</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>key CIs from UOW, UQ and UNSW, 1 HDR student<br />
                  <span className="font-semibold">Industry Partners: </span>SNS and Top Iron
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Big data analytics</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>key CIs from UOW, and Curtin, 1 HDR<br />
                  <span className="font-semibold">Industry Partners: </span>SNS and Roobuck
              </p>
          </div>
        </div>
    </>
  )
}

export default Program3;