import React from "react";
import program4 from './../assets/programs/program4_logo.jpg';

const Program4 = () => {
  return (
    <>
      <p class="my-4 text-normal text-gray-600">
      This program is concentrating on resource extraction environments, mine safety and maintenance and reliability engineering studies.
        </p>
        <h2 class="text-lg font-semibold mb-2">Key Researchers and Organizations</h2>
        <ul class="list-disc list-inside mb-4">
            <li>Key CI: M. Karakus (Adelaide)</li>
            <li>CIs: M. Kizil (UQ), T. Ren (UOW), S. Karekal (UOW), V. Pareek (Curtin), G. Si (UNSW), D. Hastie (UOW)</li>
            <li>POs: Baosteel, Bisalloy, Roobuck, Komatsu, SNS, and Australia L&Y</li>
        </ul>
        <img src={program4} alt="arc-program4" className="md:w-1/2 object-cover w-4/5 block m-auto py-5"/>
        <h2 class="text-lg font-semibold mb-2">Focus Areas</h2>
        <div className="ml-4">
          <div class="mb-4">
              <h3 class="text-normal font-medium mb-1 text-blue-800">Impact Strength Test</h3>
              <p class="ml-4">
                  <span class="font-semibold">Researchers:</span> Key CIs from Deakin, UNSW, and UOW and 1 HDR<br />
                  <span class="font-semibold">Industry Partners:</span> PIs from Roobuck and Bisalloy
              </p>
          </div>
          <div class="mb-4">
                <h3 class="text-normal font-medium mb-1 text-blue-800">Control of Mining Equipment Operational Environments</h3>
                <p class="ml-4">
                    <span class="font-semibold">Researchers:</span> Key CI from Adelaide, CIs from UQ and UNSW with 1 HDR<br />
                    <span class="font-semibold">Industry Partners:</span> PIs from SNS and Australia L&Y
                </p>
            </div>
            <div class="mb-4">
                <h3 class="text-normal font-medium mb-1 text-blue-800">Personnel and Equipment Safety of Underground Mining Operations</h3>
                <p class="ml-4">
                    <span class="font-semibold">Researchers:</span> CIs from UOW and UQ with 1 HDR<br />
                    <span class="font-semibold">Industry Partners:</span> PIs from Roobuck and Baosteel
                </p>
            </div>
            <div class="mb-4">
                <h3 class="text-normal font-medium mb-1 text-blue-800">Assessment of the Safety, Reliability, and Performance of Mining Equipment</h3>
                <p class="ml-4">
                    <span class="font-semibold">Researchers: </span> CIs from Curtin and UOW, and 1 HDR<br />
                    <span class="font-semibold">Industry Partners: </span> PIs from Komatsu, Bisalloy, SNS, and Australia L&Y
                </p>
            </div>
        </div>
    </>
  )
}

export default Program4;