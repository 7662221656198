import React from "react";
import program2 from './../assets/programs/program2_logo.jpg';

const Program2 = () => {
  return (
    <>
        <p className="my-4 text-normal text-gray-600">
        This program is focusing on the development of novel composites for mining equipment, theoretical analysis, multiscale simulation, and practical hot forming, heat treatment and shaping of mining equipment parts and components.
        </p>
        <h2 className="text-lg font-semibold mb-4">Key Researchers and Organizations</h2>
        <ul className="list-disc list-inside mb-4 text-gray-800">
            <li>Key CI: H. Huang (UQ); CIs: M. Lu (UQ), J. Zhang (UNSW), Z. Jiang (UOW), A. Remennikov (UOW), J. Yoon (Deakin), J. Robertes (UOW)</li>
            <li>POs: Baosteel, HBIS, Bisalloy, Roobuck, Komatsu and SNS.</li>
        </ul>
        <img src={program2} alt="arc-program2" className="md:w-1/2 object-cover w-4/5 block m-auto py-5"/>
        <h2 className="text-lg font-semibold mb-2">Focus Areas</h2>
        <div className="ml-4">
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Theoretical and mechanism analysis of the forming behaviour of steel composites</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>key CIs from UOW, UQ, Deakin and UNSW, 2 HDR students<br />
                  <span className="font-semibold">Industry Partners: </span>Baosteel and HBIS
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Multiscale numerical simulation and hot forming behaviour of steel composites</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>key CIs from UOW, UQ and Deakin, 1 ICPD and 2 HDR students<br />
                  <span className="font-semibold">Industry Partners: </span>Roobuck, Komatsu, Bisalloy and HBIS
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Heat treatment of the processed composites</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>Key CIs from UOW and UNSW, 1 HDR student<br />
                  <span className="font-semibold">Industry Partners: </span>SNS and Bisalloy
              </p>
          </div>
          <div className="mb-4">
              <h3 className="text-normal font-medium mb-1 text-blue-800">Novel manufacturing process development of steel composites</h3>
              <p className="ml-4">
                  <span className="font-semibold">Researchers: </span>key CIs from UOW and Deakin, 1 HDR <br />
                  <span className="font-semibold">Industry Partners: </span>Komatsu, Roobuck and Baosteel
              </p>
          </div>
        </div>
    </>
  )
}

export default Program2;