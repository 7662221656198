import React from "react";
import { news } from "../data/news";
import News from "./News";
import { useNavigate } from "react-router-dom";

const LatestNews = () => {

  const navigate = useNavigate();

  return (
    <div className="m-4">
      <h1 className="text-light-orange uppercase text-xl md:text-3xl font-bold">Latest News</h1>
      <div className="m-4 md:m-8 flex-row flex flex-wrap justify-start items-stretch">
        {[...news].slice(0, 4).map((item, idex) => {
          return (
            <News {...item} key={`news-${idex}`} />
          )
        })}
        <div
         className="text-xl font-bold text-red-500 flex flex-col justify-center">
          <div 
            onClick={() => navigate('/news')}
          className="hover:opacity-90 hover:underline m-2 cursor-pointer"
          >
          See more news &gt;&gt;
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestNews;