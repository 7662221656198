import React from "react";
import { useNavigate } from "react-router-dom";

const News = (prop) => {

  const {url, img, date, description, id} = prop;
  const navigate = useNavigate();

  return (
    <div className="flex flex-row flex-wrap w-64 hover:opacity-90 hover:underline m-2 cursor-pointer">
    {url ? <a href={url}  rel="noreferrer" target="_blank">
      <div>
        <img src={img} alt="news-pic" className="w-64 h-48 object-cover" />
      </div>
      <div>
        <div className="font-semibold text-red-500 uppercase text-md md:text-lg">
          {date}
        </div>
        <div className="text-gray-500">
          {description}
        </div>
      </div>
    </a>
    : 
    <div onClick={() => navigate(`news/${id}`)}>
          <div>
        <img src={img} alt="news-pic" className="w-64 h-48 object-cover" />
      </div>
      <div>
        <div className="font-semibold text-red-500 uppercase text-md md:text-lg">
          {date}
        </div>
        <div className="text-gray-500">
          {description}
        </div>
      </div>
    </div>
    }
  </div>
  )
}

export default News;