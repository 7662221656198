import React from "react";
import { Avatar } from "@mui/material";
import { unis } from "../data/members";

const UniCard = (prop) => {

  const {avatar, name, title, uni, url} = prop

  return (
    <div className="flex flex-row gap-4 my-4 justify-around">
      <div>
        <Avatar 
            src={avatar} alt={`${name}`} style={{ width: "60px", height: "60px" }}/>
      </div>
      <div className="flex-auto">
        <a href={url} rel="noreferrer" target="_blank">
          <p className="text-lg font-semibold hover:underline cursor-pointer">
            <span className="font-normal italic">{title}.</span>
            &nbsp;{name}
          </p>
        </a>
        <p className="text-semibold text-blue-950 text-normal">
          {unis[uni].name}
        </p>
      </div>
    </div>
  )
}

export default UniCard;