import React from "react";
import opportunity from "./../assets/image/opportunity.jpg"
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Opportunities = () => {

  const navigate = useNavigate();

  return (
    <div className="bg-gray-800 md:text-xl xl:text-2xl flex md:flex-row flex-col no-wrap md:p-3 md:m-10 lg:m-14 p-2 m-2 justify-around text-white md:flex-wrap-reverse">
      <div className="md:w-1/2 p-4 w-full flex flex-col justify-between">
        <div className="text-base font-light text-md">
          <Typography variant="h4" sx={{fontWeight: 800}}>Current Opportunities</Typography>
          We are seeking dynamic, self-motivated and well-qualified candidates with high-quality research experience in the fields of expertise applicable to the relevant opportunity. You must have a passion for applied, industry-focused research, and high quality communication skills to support successful industry collaborations. 
        </div>
        <div>
          <Button onClick={() => {navigate('/current-opportunities')}} variant="outlined" color="secondary" sx={{bgcolor: "#fff", "&:hover": {"bgcolor": "#e8e8e8"}, width: "200px", height:"60px", borderRadius: 0, border: 0, fontWeight: 600}}>See Opportunities</Button>
        </div>
      </div>
      <div className="md:w-1/2 p-4 w-full">
        <img src={opportunity} alt="opportunity" className="w-full" />
      </div>
    </div>
  )
}

export default Opportunities;