import React from "react";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Avatar } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Card = (prop) => {

  const {title, name, position, avatar, tel, email, contact, url} = prop;

  return (
    <div className="flex flex-row flex-wrap gap-4 my-12 justify-around">
      <div className="">
        <Avatar 
          src={avatar} alt={`${name}`} sx={{ height: "130px", width: "130px" }}/>
      </div>
      <div className="flex-auto w-2/3">
        <p className="text-xl font-bold text-light-orange">{position}</p>
        <a href={url} rel="noreferrer" target="_blank">
          <p className="text-lg font-semibold hover:underline cursor-pointer">
            <span className="font-normal italic">{title}. </span>
            &nbsp;{name}&nbsp;&nbsp;&nbsp;
            <OpenInNewIcon size="small" />
          </p>
        </a>
        {contact && 
          <>
            <p className="text-neutral-600 text-sm py-1"><LocalPhoneIcon />&nbsp;&nbsp;{tel}</p>
            <p className="text-neutral-600 text-sm py-1"><EmailIcon />&nbsp;&nbsp;{email}</p>
            {/* <p className="text-neutral-600 text-sm py-1"><a href={url} rel="noreferrer" target="_blank"><OpenInNewIcon sx={{color: blue[900]}} />&nbsp;&nbsp;Link</a></p> */}
          </>
        }
      </div>
    </div>
  )
}

export default Card;