import React from "react";
import { Outlet } from "react-router-dom";
import { AppHeader } from "./components/AppHeader";
import Footer from "./components/Footer";

export const Site = () => {

  return (
    // <div className={`main-wrapper-${device}`}>
    //   {device === "screen" ? 
    //   <div className="menu">
    //     <SideBar setSelectedIndex={setSelectedIndex} selectIndex={selectIndex} />
    //   </div>
    //   : <Header setSelectedIndex={setSelectedIndex} selectIndex={selectIndex} />
    // }
    //   <div className="main-container">
    //     <div className="main-content">
    //       <Outlet />
    //     </div>
    //     <div className="main-footer">
    //     </div>
    //   </div>
    // </div>
  <div>
    <AppHeader />
    <div style={{marginTop: "80px"}}>
      <Outlet />
    </div>
    <Footer />
  </div>
  )
}