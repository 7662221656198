import { AppBar, Box, List, ListItem,ListItemButton, ListItemText, Toolbar, IconButton, Drawer, Button } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo/logo-rect.jpg';
import { NavLink, useNavigate } from "react-router-dom";

export const AppHeader = () => {

  const navItems = ["Home", "Overview", "Our People", "Our Research", "Current opportunities", "News",
  "Contact us",]

  const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };

    const navigate = useNavigate();
    const handleClick = (name) => {
      const url = name.split(' ').join('-').toLowerCase();
      navigate(url==="home" ? "/" : url);
    }
  
    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <List>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding onClick={() => handleClick(item)}>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  
    return (
      <Box sx={{ maxWidth: "100vw"}}>
        <AppBar position="fixed" component="nav" sx={{height: "80px", bgcolor: "#fff",  maxWidth: "100vw", left: 0 }}>
          <Toolbar disableGutters sx={{justifyContent: "space-between", padding: "0"}}>
            <div style={{height: "100%", flexShrink: 0, cursor: "pointer"}} onClick={() => {navigate('/')}}>
              <img src={logo} alt="logo" style={{height: "72px", width: "auto", margin: "4px"}}/>
            </div>
            <Box sx={{ display: {xs: 'none', sm: 'none', md: 'block' }, float: "right"}}>
              {navItems.map((item, index) => (
                <NavLink 
                  key={`link-${index}`}
                  style={({ isActive }) => {
                  return {
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "#ff9800" : "#011640",
                  };
                }} to={`/${item==="Home" ? "" : item.split(' ').join('-').toLowerCase()}`}>
                  <Button key={item} sx={{ fontWeight: 800, color: "inherit" }}>
                    {item}
                  </Button>
                </NavLink>
              ))}
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    )
  }
      