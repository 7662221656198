import React from "react";
import baostell from './../assets/logo/baosteel.png'
import bisalloy from './../assets/logo/bisalloy.svg'
import hibs from './../assets/logo/hibs.png'
import deakinuni from './../assets/logo/deakin.png'
import komatsu from './../assets/logo/komatsu.svg'
import nswGover from './../assets/logo/nswGover.png'
import roobuck from './../assets/logo/Roobuck-Logo1.png'
import sns from './../assets/logo/sns.png'
import topiron from './../assets/logo/topiron.jpg'
import unsw from './../assets/logo/unsw.png'
import uoa from './../assets/logo/UoA.png'
import uow from './../assets/logo/uow.svg'
import uq from './../assets/logo/UQ.png'
import wsu from './../assets/logo/WSU.png'
import curtin from './../assets/logo/curtin_uni.png'
import au_arc from './../assets/logo/aus_arc.jpg'
import lymine from './../assets/logo/lymine.jpg';
import azure from './../assets/logo/azure_mining_technology_logo.jpg';

const Partners = () => {

  const partners = [
    {
      pic: au_arc,
      url: "https://www.arc.gov.au/"
    },
    {
      pic: uow,
      url: "https://www.uow.edu.au/"
    },
    {
      pic: nswGover,
      url: "https://www.nsw.gov.au/"
    },
    {
      pic: baostell,
      url: "https://www.baosteel.com/en/home",
    },
    {
      pic: uq,
      url: "https://www.uq.edu.au/"
    },
    {
      pic: roobuck,
      url: "https://www.roobuck.com.au/"
    },
    {
      pic: bisalloy,
      url: "https://www.bisalloy.com.au/"
    },
    {
      pic: deakinuni,
      url: "https://www.deakin.edu.au/"
    },
    {
      pic: curtin,
      url: "https://www.curtin.edu.au/",
    },
    {
      pic: wsu,
      url: "https://www.westernsydney.edu.au/",
    },
    {
      pic: unsw,
      url: "https://www.unsw.edu.au/"
    },
    {
      pic: hibs,
      url: "https://www.hbisco.com/en/"
    },
    {
      pic: komatsu,
      url: "https://www.komatsu.com.au/"
    },
    {
      pic: uoa,
      url: "https://www.adelaide.edu.au/",
    },
    {
      pic: sns,
      url: "http://snsunicorp.com.au"
    },
    {
      pic: topiron,
      url: ""
    },
    {
      pic: lymine,
      url: ""
    },
    {
      pic: azure,
      url: "https://amtccteg.com.au/company"
    }
  ]

  return (
      <div className="flex flex-row flex-wrap content-start items-center justify-around">
        {[...partners].map((partner, index) => {
          return (
            partner.url ? 
            <a href={partner.url} key={`partner-${index}`} className="hover:opacity-80" target="_blank" rel="noreferrer">
            <div className="md:w-48 w-36 m-4 md:m-8">
              <img className={`w-full`} src={partner.pic} alt={`logo-partner-${index}`} />
            </div>
            </a>
            :
            <span key={`partner-${index}`}>
            <div className="md:w-48 w-36 m-4 md:m-8">
              <img className={`w-full`} src={partner.pic} alt={`logo-partner-${index}`} />
            </div>
            </span>
          )
        })}
      </div>
  )
}

export default Partners;